
import { SliderModel } from "../model/Slider.model";
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  name: "SliderPanel",
  created() {
    this.fetchSlider();
  },
  data() {
    return {
      sliders: [],
    };
  },
  methods: {
    fetchSlider() {
      this.axios
        .get(process.env.VUE_APP_API_URL + "xapi/v2/sliders")
        .then((response: any) => {
          this.sliders = response.data;
        });
    },
    getImg(img: string) {
      return process.env.VUE_APP_API_URL + "assets/images/carousel/5/" + img;
    },
  },
});
