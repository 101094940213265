import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-350ed072"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"background-color":"#e6e8eb"} }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = {
  class: "col-sm-3",
  style: {"margin-top":"20px"}
}
const _hoisted_5 = {
  class: "left-sidebar kategori",
  style: {"background-color":"#fff"}
}
const _hoisted_6 = {
  class: "panel-group category-products",
  id: "accordian"
}
const _hoisted_7 = { class: "panel-title" }
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  class: "brands_products",
  style: {"background-color":"#fff"}
}
const _hoisted_10 = ["src"]
const _hoisted_11 = {
  class: "col-sm-9 padding-right",
  style: {"padding-left":"4px !important","margin-top":"20px"}
}
const _hoisted_12 = { class: "single-products" }
const _hoisted_13 = {
  class: "productinfo text-center",
  style: {"background-color":"#fff","height":"325px"}
}
const _hoisted_14 = ["src"]
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { style: {"padding-right":"15px","padding-left":"15px","padding-bottom":"20px","text-overflow":"ellipsis"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_panel = _resolveComponent("header-panel")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_footer_panel = _resolveComponent("footer-panel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_header_panel),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "panel panel-default",
                    key: category.id
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["panel-heading", { active: this.slug === category.url }])
                    }, [
                      _createElementVNode("h4", _hoisted_7, [
                        _createVNode(_component_router_link, {
                          to: '/kategori/' + category.url
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("img", {
                              src: _ctx.getIcon(category.icon),
                              style: {"width":"15px","height":"15px","margin-right":"5px"}
                            }, null, 8, _hoisted_8),
                            _createTextVNode(" " + _toDisplayString(category.nama_kategori), 1)
                          ]),
                          _: 2
                        }, 1032, ["to"])
                      ])
                    ], 2)
                  ]))
                }), 128))
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ads, (ad) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "shipping text-center ads",
                  key: ad.id_slider
                }, [
                  _createElementVNode("img", {
                    src: _ctx.getAdImage(ad.iklan),
                    style: {"width":"250px","object-fit":"fill"}
                  }, null, 8, _hoisted_10)
                ]))
              }), 128))
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.produk, (p) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "col-sm-3 col-xs-6",
                key: p.id_produk
              }, [
                _createElementVNode("div", {
                  class: "product-image-wrapper hover",
                  onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hover = true)),
                  onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hover = false))
                }, [
                  _createVNode(_component_router_link, {
                    to: '/produk/' + p.url
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("div", _hoisted_13, [
                          _createElementVNode("img", {
                            src: _ctx.getProdukImage(p.thumb),
                            style: {"height":"180px","object-fit":"cover"}
                          }, null, 8, _hoisted_14),
                          (p.harga_1 != 0)
                            ? (_openBlock(), _createElementBlock("h2", _hoisted_15, _toDisplayString(_ctx.formatCurr(parseInt(p.harga_1))), 1))
                            : (_openBlock(), _createElementBlock("h2", _hoisted_16, "Rp (hubungi kami)")),
                          _createElementVNode("h4", _hoisted_17, _toDisplayString(_ctx.limitWord(p.nama_produk, 25)), 1)
                        ])
                      ])
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ], 32)
              ]))
            }), 128))
          ])
        ])
      ])
    ]),
    _createVNode(_component_footer_panel)
  ], 64))
}