
import { defineComponent } from "@vue/runtime-core";
import HeaderPanel from "../components/Header.vue";
import FooterPanel from "../components/Footer.vue";
import VueEasyLightbox from "vue-easy-lightbox";

export default defineComponent({
  name: "ProdukDetail",
  components: {
    HeaderPanel,
    FooterPanel,
    VueEasyLightbox,
  },
  inject: ["mq"],
  created() {
    this.fetchIklan();
    this.fetchCategories();
    this.fetchDetailProduk(this.slug);
  },
  watch: {
    dvarian: function (val) {
      this.produk_data.data_varian.find((e: any) => {
        if (e.varian_ukuran === val) {
          this.price = this.produk_data.price * 1 + e.perubahan_harga * 1;
          this.stok = e.stok;
        }
      });
    },
  },
  data() {
    return {
      imgs: "",
      stok: 0 as number,
      qty: 1,
      slug: this.$route.params.slug as string,
      produk_data: {} as any,
      toko_data: {} as any,
      categories: Array(),
      id_toko: "",
      varian: false,
      dvarian: "S",
      price: 0 as number,
      visible: false,
      index: 0,
      catatan: "",
      ads: Array(),
    };
  },
  methods: {
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    showImg(img: string) {
      this.imgs = img;
      this.show();
    },
    minus() {
      if (this.qty > 1) {
        this.qty--;
      }
    },
    plus() {
      if (this.qty < 999) {
        this.qty++;
      }
    },
    orderViaWa() {
      let text = "*ORDER BARU*";
      text += "%0A%0A";
      text += "*LAPAK* : %0A" + this.toko_data.nama_toko + "%0A";
      text += "*PRODUK* : %0A" + this.produk_data.name + "%0A";
      if (this.varian) {
        text += "*UKURAN* : %0A" + this.dvarian + "%0A";
      }
      text += "*QTY* : " + this.qty + "%0A";
      text += "*CATATAN* : %0A" + this.catatan + "%0A";
      location.href = "http://wa.me/" + this.produk_data.wa + "/?text=" + text;
    },
    fetchDetailProduk(slug: string) {
      var bodyFormData = new FormData();
      bodyFormData.append("slug", slug);
      this.axios
        .post(
          process.env.VUE_APP_API_URL + "xapi/v2/products/detail_by_slug",
          bodyFormData
        )
        .then((response: any) => {
          this.produk_data = response.data;
          this.price = this.produk_data.price as number;
          this.stok = this.produk_data.stok as number;
          if (this.produk_data.data_varian.length > 0) {
            this.varian = true;
          }
          this.fetchDetailToko(response.data.id_toko);
        });
    },
    fetchDetailToko(id_toko: string) {
      var bodyFormData = new FormData();
      bodyFormData.append("id_toko", id_toko);
      this.axios
        .post(process.env.VUE_APP_API_URL + "xapi/toko/detail", bodyFormData)
        .then((response: any) => {
          this.toko_data = response.data;
        });
    },
    fetchCategories() {
      this.axios
        .get(process.env.VUE_APP_API_URL + "xapi/v2/categories")
        .then((response: any) => {
          this.categories.push(...response.data);
        });
    },
    getIcon(icon: string) {
      return process.env.VUE_APP_API_URL + "assets/images/icon-new/" + icon;
    },
    getTokoLogo(gambar: string) {
      return process.env.VUE_APP_API_URL + "assets/images/toko/logo/" + gambar;
    },
    getProductImage(gambar: string) {
      return process.env.VUE_APP_API_URL + "assets/images/thumbnail/" + gambar;
    },
    formatCurr(num: number) {
      return Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        maximumSignificantDigits: 3,
      }).format(num);
    },
    fetchIklan() {
      this.axios
        .get(process.env.VUE_APP_API_URL + "xapi/iklan_samping")
        .then((response: any) => {
          this.ads.push(...response.data);
        });
    },
    getAdImage(image: string) {
      return (
        process.env.VUE_APP_API_URL + "assets/images/iklan_samping/" + image
      );
    },
  },
});
