import { createApp } from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios'
import App from './App.vue'
import HomePage from './pages/Home.vue'
import LoginPage from './pages/Login.vue'
import ProdukDetail from './pages/ProdukDetail.vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import { Vue3Mq } from 'vue3-mq'
import TokoPage from './pages/Toko.vue'
import CategoriesPage from './pages/Categories.vue'
import VueEasyLightbox from 'vue-easy-lightbox'


const routes = [
    {
        path: '/',
        component: HomePage
    },
    {
        path: '/login',
        component: LoginPage
    },
    {
        path: '/produk/:slug',
        component: ProdukDetail
    },
    {
        path: '/toko/:slug',
        component: TokoPage
    },
    {
        path: '/kategori/:slug',
        component: CategoriesPage
    }
    
]
const router = createRouter({
    history: createWebHashHistory(),
    routes
});
const app = createApp(App).use(VueAxios, axios).use(router).use(Vue3Mq, {
    preset: 'bootstrap3'
});
app.use(VueEasyLightbox);
app.provide('axios', app.config.globalProperties.axios)
app.mount('#app')
