
import { defineComponent } from "@vue/runtime-core";
import HeaderPanel from "../components/Header.vue";
import SliderPanel from "../components/Slider.vue";
import CategoriesPanel from "../components/Categories.vue";
import FooterPanel from "../components/Footer.vue";

export default defineComponent({
  name: "HomePage",
  created() {
    this.fetchIklan();
    this.fetchCategories();
    this.fetchProdukByCategories("21", "0");
  },
  data() {
    return {
      level:
        localStorage.getItem("level") !== null
          ? localStorage.getItem("level")
          : 0,
      ads: Array(),
      categories: Array(),
      produk: Array(),
      hover: false,
    };
  },
  components: {
    HeaderPanel,
    SliderPanel,
    CategoriesPanel,
    FooterPanel,
  },
  methods: {
    limitWord(str: string, limit: number) {
      if (str.length > limit) {
        return str.substring(0, limit) + "...";
      } else {
        return str;
      }
    },
    formatCurr(num: number) {
      return Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        maximumSignificantDigits: 3,
      }).format(num);
    },
    fetchCategories() {
      this.axios
        .get(process.env.VUE_APP_API_URL + "xapi/v2/categories")
        .then((response: any) => {
          this.categories.push(...response.data);
        });
    },
    fetchProdukByCategories(id_kategori: string, level: string) {
      var bodyFormData = new FormData();
      bodyFormData.append("id_kategori", id_kategori);
      bodyFormData.append("level", level);
      bodyFormData.append("unggulan", "1");
      this.axios
        .post(
          process.env.VUE_APP_API_URL + "xapi/v2/products/get_by_category",
          bodyFormData
        )
        .then((response: any) => {
          if (this.produk.length > 0) {
            this.produk = [];
            this.produk.push(...response.data);
          } else {
            this.produk.push(...response.data);
          }
        });
    },
    fetchIklan() {
      this.axios
        .get(process.env.VUE_APP_API_URL + "xapi/iklan_samping")
        .then((response: any) => {
          this.ads.push(...response.data);
        });
    },
    getAdImage(image: string) {
      return (
        process.env.VUE_APP_API_URL + "assets/images/iklan_samping/" + image
      );
    },
    getIcon(icon: string) {
      return process.env.VUE_APP_API_URL + "assets/images/icon-new/" + icon;
    },
    getProdukImage(image: string) {
      return (
        process.env.VUE_APP_API_URL + "assets/images/thumbnail/thumb/" + image
      );
    },
  },
});
