
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  name: "FooterPanel",
  created() {
    this.fetchCategories()
  },
  data() {
    return {
      sponsors: Array(),
    };
  },
  methods: {
    getCurrentYear() {
      return new Date().getFullYear();
    },
    fetchCategories() {
      this.axios
        .get(process.env.VUE_APP_API_URL + "xapi/logo_sponsor")
        .then((response: any) => {
          this.sponsors.push(...response.data);
        });
    },
    getLogo(logo: string) {
      return process.env.VUE_APP_API_URL + "assets/images/logo_sponsor/" + logo;
    },
  },
});
