
import { defineComponent } from "@vue/runtime-core";
import HeaderPanel from "../components/Header.vue";
import FooterPanel from "../components/Footer.vue";

export default defineComponent({
  name: "LoginPage",
  components: {
    HeaderPanel,
    FooterPanel,
  },
});
