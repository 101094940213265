
import { defineComponent } from "@vue/runtime-core";
import HeaderPanel from "../components/Header.vue";
import FooterPanel from "../components/Footer.vue";

export default defineComponent({
  name: "TokoPage",
  components: {
    HeaderPanel,
    FooterPanel,
  },
  data() {
    return {
      slug: this.$route.params.slug as string,
      toko_data: {} as any,
      categories: Array(),
      ads: Array(),
      produk: Array(),
      id_toko: "",
    };
  },
  inject: ["mq"],
  created() {
    this.fetchDetailToko(this.slug);
    this.fetchIklan();
    this.fetchCategories();
  },
  methods: {
    //limit word
    limitWord(str: string, limit: number) {
      if (str.length > limit) {
        return str.substring(0, limit) + "...";
      } else {
        return str;
      }
    },
    fetchByIdToko(id_toko: any) {
      var bodyFormData = new FormData();
      bodyFormData.append("id_toko", id_toko);
      this.axios
        .post(
          process.env.VUE_APP_API_URL + "xapi/v2/products/get_by_id_toko",
          bodyFormData
        )
        .then((response) => {
          this.produk.push(...response.data);
        });
    },
    fetchCategories() {
      this.axios
        .get(process.env.VUE_APP_API_URL + "xapi/v2/categories")
        .then((response: any) => {
          this.categories.push(...response.data);
        });
    },
    getIcon(icon: string) {
      return process.env.VUE_APP_API_URL + "assets/images/icon-new/" + icon;
    },
    getTokoLogo(gambar: string) {
      return process.env.VUE_APP_API_URL + "assets/images/toko/logo/" + gambar;
    },
    getProdukImage(image: string) {
      return (
        process.env.VUE_APP_API_URL + "assets/images/thumbnail/thumb/" + image
      );
    },
    fetchDetailToko(slug: string) {
      var bodyFormData = new FormData();
      bodyFormData.append("slug", slug);
      this.axios
        .post(process.env.VUE_APP_API_URL + "xapi/v2/toko/get_toko_by_slug", bodyFormData)
        .then((response: any) => {
          this.toko_data = response.data;
          this.fetchByIdToko(this.toko_data.id);
        });
    },
    fetchIklan() {
      this.axios
        .get(process.env.VUE_APP_API_URL + "xapi/iklan_samping")
        .then((response: any) => {
          this.ads.push(...response.data);
        });
    },
    getAdImage(image: string) {
      return (
        process.env.VUE_APP_API_URL + "assets/images/iklan_samping/" + image
      );
    },
    formatCurr(num: number) {
      return Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        maximumSignificantDigits: 3,
      }).format(num);
    },
  },
});
