
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  name: "CategoriesPanel",
  inject: ["mq"],
  created() {
    this.fetchSliders();
  },
  data() {
    return {
      sliders: Array(),
    };
  },
  methods: {
    fetchSliders() {
      this.axios
        .get(process.env.VUE_APP_API_URL + "xapi/slider_sponsor")
        .then((response: any) => {
          this.sliders.push(...response.data);
        });
    },
    getImage(icon: string) {
      return process.env.VUE_APP_API_URL + "assets/images/carousel_sponsor/" + icon;
    },
  },
});
